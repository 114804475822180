import { Grid } from "@material-ui/core";
import SelectInput from "./SelectInput";

const SelectGrid = (properties) => {
  return (
    <Grid item xs={properties.xs || 12} sm={properties.sm || 12} md={properties.md || 12} lg={properties.lg || 12}>
      <SelectInput {...properties} />
    </Grid>
  )
}
export default SelectGrid;