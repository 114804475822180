import { Grid } from "@material-ui/core";
import React from "react";
import If from "../../../utils/If";
import LittleTitle from "./LittleTitle";

const styles = {
  paper: {
    backgroundColor: "#f9f9f9",
    border: "1px solid #f0f0f0",
    // boxShadow: '1px 1px 5px #bbbbbb',
    padding: 20,
    borderRadius: 7,
    color: "#444444",
		margin: 5,
    marginBottom: 10,
  },
};

const BoxPanel = (props) => {
  return (
    <Grid container spacing={2} style={styles.paper}>
      <If value={props.hasOwnProperty("title")}>
        <LittleTitle> {props.title} </LittleTitle>
      </If>
      {props.children}
    </Grid>
  );
};
export default BoxPanel;
