import { TextField } from "@material-ui/core";

const InputText = ({value, name, label, disabled, onChange, onKeyDown}) => (
    <TextField 
      fullWidth
      style={{backgroundColor:'#ffffff'}}
      InputLabelProps={{ shrink: true }} shrink
      disabled={ disabled === undefined ? false : disabled }
      autoComplete={name}
      name={name}
      variant="outlined"
      label={label}
      size="small"
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
      // {...prop}
    />
  );
export default InputText;