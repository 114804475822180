import React from "react";

export default function ProductDescription(props) {
  return (
    <div style={{ display: 'flex', height: ( props.height !== undefined ? props.height : 200 )}}>
      {!props.data ? (
      <div></div>
      ) : (
      <React.Fragment>
        <div style={styles.columnOne}>

          <h2 style={styles.code}>
            {props.data.code && props.data.code}
          </h2>
          <div style={styles.imagePadding}>
            {/* <img src={props.imagesSelected.length > 0 ? props.imagesSelected[0] : null} /> */}
            {props.data.code && (
              <img src={`https://valpec.gnnc.com.br/storage/products/${props.data.code}.jpg`} alt={props.data.code} title={props.data.code} style={styles.image}/>
            )}
          </div>

        </div>
        <div style={styles.columnTwo}>

          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <p style={styles.title}>Montadora</p>
              <div style={styles.detail}>
                {props.data.maker && props.data.maker}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              {/* <p style={styles.title}>Dimensão</p>
              <div style={styles.detail}>
                {props.data.dimension && props.data.dimension}
              </div> */}
            </div>
          </div>
          <p style={styles.title}>Montor</p>
          <div style={styles.detail}>
            {props.data.motor && props.data.motor}
          </div>
          <p style={styles.title}>Linha</p>
          <div style={styles.detail}>
            {props.data.group_line && props.data.group_line}
          </div>
          <p style={styles.title}>Dimensão</p>
          <div style={styles.detail}>
            {props.data.dimension && props.data.dimension}
          </div>

        </div>
        <div style={{ flex: 1, padding: 5 }}>

          <p style={styles.title}>Código Original</p>
          <div style={styles.detail}>
            {props.data.code_original && props.data.code_original}
          </div>
          <p style={styles.title}>Dimensão</p>
          <div style={styles.detail}>
            {props.data.code_similar && props.data.code_similar}
          </div>
          <div style={styles.application}>
            {props.data.code_original_2 && props.data.code_original_2}
          </div>
          <p style={styles.title}>Aplicação</p>
          <div style={styles.application}>
            {props.data.application && props.data.application}
          </div>

        </div>
      </React.Fragment>
      )}
    </div>
  );
};

const styles = {
  columnOne: {
    width: 200, 
    padding: 5,
  },
  columnTwo: {
    width: 280, 
    padding: 5,
  },
  imagePadding: {
    textAlign: 'center',
    paddingTop: 5,
  },
  image: {
    maxHeight: 140,
  },
  code: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: '#eeeeee',
    borderRadius: 7,
    fontSize: 22,
    fontWeight: 300,
    color: '#222222',
    padding: 0,
    margin: 0,
    marginBottom: 2,
    marginTop: 3,
  },
  title: {
    fontSize: 12,
    color: '#555555',
    padding: 0,
    margin: 0,
    marginBottom: 2,
  },
  detail: {
    fontSize: 12,
    fontWeight: 700,
    color: '#000000',
    padding: 0,
    margin: 0,
    marginBottom: 2,
    minHeight: 18,
  },
  application: {
    fontSize: 12,
    color: '#000000',
    padding: 0,
    margin: 0,
    marginBottom: 2,
    maxHeight: 72,
    overflow: 'hidden',
    // border: '1px solid #000'
  },
}