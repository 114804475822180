import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Backdrop, Checkbox, CircularProgress, Divider, FormControlLabel, LinearProgress, makeStyles } from '@material-ui/core';
import GlobalEvents from '../../../../resources/event/GlobalEvents';
import UtilsFilter from '../../../../resources/utils/UtilsFilter';
import If from '../../../../resources/utils/If';
//import { useSnackbar } from 'notistack';
import BoxPanel from '../../../../resources/components/general/views/BoxPanel';
import InputText from '../../../../resources/components/general/inputs/InputText';
import InputTextGrid from '../../../../resources/components/general/inputs/InputTextGrid';
import ApiServer from '../../../../services/ApiServer';
import SelectGrid from '../../../../resources/components/general/selects/SelectGrid';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: 30,
  },
  title: {
    padding: 0,
    margin: 0,
    marginBottom: 10,
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
  divider: {
    marginBottom: 15,
    marginTop: 10,
    width: '100%'
  },
}));

/**
 * email Gisele
 * 
 * GRUPO: CONJUTO VISCOSO - LINHA CONJUNTOS / VENTILADORES
 * GRUPO: EMBREAGEM VISCOSA - LINHA EMBREAGEM VISCOSA
 * GRUPO: HELICES - LINHA HÉLICES / VENTILADORES
 * GRUPO: ALUMINIO - LINHA FERRO * LINHA ALUMINIO
 * GRUPO: AGRICOLA – ALUMINIO * EMBREAGEM VISCOSA * HELICES/VENTILADORES
 * 
 * Exemplo Menu
 * 
 * MERCEDES: 
 * CONJUNTOS VISCOSO
 *    EMBREAGEM VISCOSA
 *    HELICES / VENTILADORES
 *    PEÇAS EM FERRO
 *    PEÇAS EM ALUMINIO
 * 
 * AGRICOLA: 
 * EMBREAGEM VISCOSA
 *    HELICES / VENTILADORES
 *    PEÇAS EM ALUMINIO 
 */

const dataMakers = [
  'AGRALE',
  'CASE',
  'CATERPILLAR',
  'CBT',
  'CUMMINS',
  'DAF',
  'DEUTS',
  'DOOSAM',
  'DYNAPAC',
  'FIAT ALLIS',
  'FORD',
  'GERADORES',
  'GM',
  'HUBER',
  'HYUNDAI',
  'IDEAL',
  'INTERNACIONAL',
  'IVECO',
  'JACTO',
  'JCB',
  'JOHN DEERE',
  'KOMATSU',
  'MARITIMO',
  'MASSEY',
  'MERCEDES',
  'MULLER',
  'NEW HOLLAND',
  'RANDON',
  'SCANIA',
  'SINOTRUK',
  'TIANGONG',
  'UTILITÁRIO',
  'VALTRA',
  'VOLKSWAGEN',
  'VOLVO',
  'WORD',
  'XCMG',
  'YIENENG',
];

const dataGroups = [
  "ALUMÍNIO", 
  "AGRICOLA", 
  "CONJUTO VISCOSO",
  "EMBREAGEM VISCOSA", 
  "HÉLICES", 
];

const dataLines = [
  "ALUMÍNIO",
  "CONJUNTOS / VENTILADORES",
  "EMBREAGEM VISCOSA",
  "FERRO",
  "HÉLICES / VENTILADORES",
];


export default function ProductCreate(props) { 

  // universal
  const classes = useStyles();
  //const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  // const
  const [imageHost] = React.useState(process.env.REACT_APP_IMAGE_HOST);
  
  // variables
  const [name, setName]  = useState(''); 
  const [id, setId]  = useState(0); 
  const [idSystem, setIdSystem]  = useState(0); 
  const [nickName, setNickName] = useState('');
  const [code, setCode] = useState(''); 
  const [codeOriginal, setCodeOriginal] = useState(''); 
  const [codeOriginal2, setCodeOriginal2] = useState(''); 
  const [codeSimilar, setCodeSimilar] = useState(''); 
  const [maker, setMaker] = useState(''); 
  const [motor, setMotor] = useState(''); 
  const [dimension, setDimension] = useState(''); 
  const [groupLine, setGroupLine] = useState(''); 
  const [groupType, setGroupType] = useState(''); 
  const [application, setApplication] = useState(''); 
  const [tags, setTags] = useState(''); 

  const [isAgri, setIsAgri] = useState(0); 
  const [isDiesel, setIsDiesel] = useState(0); 

  const [isVisible, setIsVisible] = useState(0); 
  const [isNew, setIsNew] = useState(0); 
  const [isOff, setIsOff] = useState(0);
  const [isHighlight, setIsHighlight] = useState(0);

  const [fileName, setFileName] = useState(null);

  // ------------------------------------------------------------------ file upload
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadName, setFileUploadName] = useState('');
  const [fileUploadSize, setFileUploadSize] = useState('');
  const [fileUploadLoad, setFileUploadLoad] = useState(0);
  const [fileUploadSubmitEnabled, setFileUploadSubmitEnabled] = useState(true);

  const formFileClear = () => {
    setFileUpload(null);
    setFileUploadName('');
    setFileUploadSize('');
    setFileUploadLoad(0);
    setFileUploadSubmitEnabled(true);
  }

  const onChangeFile = (event) => {

    const f = event.target.files[0];

    if( f === undefined ) return;

    const ext = '.' + f.name.split('.').pop(); // extension 
    const nname = String(f.name).substr(0,15); // new name reduce
    const fileUploadPath = 'products';

    setFileUpload( f );
    setFileUploadName( nname + ext );
    setFileUploadSize( UtilsFilter.formatBytes(f.size,1) );

    setSubmitEnabled(false);
    setFileUploadSubmitEnabled(false);

    const form = new FormData();
    form.append('file',f);
    form.append('size',f.size);

    new ApiServer().post( 'upload', fileUploadPath , form, (e)=>{

      setFileUploadLoad( e.percent );
      if(e.percent === 100){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }

    }).then((e)=>{
      console.log(e);
      if( fileUploadLoad === 100 ){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }
      setFileName(fileUploadPath+'/' + e.data.filename);

    }).catch((e)=>{
      // enqueueSnackbar('Error upload file',{variant:'error'});
      setSubmitEnabled(true);
      setFileUploadSubmitEnabled(true);
      formFileClear();

      setFileName(null);
    });

  }
  // ------------------------------------------------------------------ file upload

  const handleChangeAgri = (event) => {
    setIsAgri(isAgri ? 0 : 1);
  };

  const handleChangeDiesel = (event) => {
    setIsDiesel(isDiesel ? 0 : 1);
  };

  const handleChangeVisible = (event) => {
    setIsVisible(isVisible ? 0 : 1);
  };

  const handleChangeOff = (event) => {
    setIsOff(isOff ? 0 : 1);
  };

  const handleChangeNew = (event) => {
    setIsNew(isNew ? 0 : 1);
  };

  const handleChangeHighlight = (event) => {
    setIsHighlight(isHighlight ? 0 : 1);
  };

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );
    setLoading( true );

    const data = {
      name: String(name).trim(),
      code: String(code).trim() || '',
      file_name: fileName || '',
    }

    new ApiServer().post( 'products', 'create', data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateMaterials');
        // enqueueSnackbar('Create a new!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      setSubmitEnabled( true );
      setLoading( false );

    }).catch((err)=>{

      console.log( err.statusText, err);
      // enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );
      setLoading( false );

    });

  };

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {

      case 'code':        setCode( String(value).toUpperCase() ); break;
      case 'id':          setId( value ); break;
      case 'id_system':   setIdSystem( value ); break;

      case 'name':        setName( value ); break;
      case 'nick_name':   setNickName( value ); break;

      case 'motor':       setMotor( value ); break;
      case 'dimension':   setDimension( value ); break;
      case 'application': setApplication( value ); break;
      case 'tags':        setTags( value ); break;

      case 'maker':       setMaker( value ); break;
      case 'group':       setGroupType( value ); break;
      case 'line':        setGroupLine( value ); break;

      // codes
      case 'codes_maker': setCodesMaker( value ); break;
      case 'codes_value': setCodesValue( value ); break;

      case 'file_name':   setFileName( value ); break;

      default: console.log('Nenhum');
        break;
    }
  };

  
  const [codesMaker, setCodesMaker] = useState('');
  const [codesValue, setCodesValue] = useState('');
  const [codes, setCodes] = useState([]);
  
  const onKeyDown = (event) => {
    if(event.key === "Enter"){
      if(event.target.name === 'codes_maker'){
        event.preventDefault();
        event.stopPropagation();
        if(event.target.value){
          document.getElementsByName('codes_value')[0].focus();
        }
      }
      if(event.target.name === 'codes_value'){
        addCodes();
        event.preventDefault();
        event.stopPropagation();
      }  
    }
  }

  const deleteCode = (index) => {
    const v = [];
    codes.forEach( (el, idx) => {
      if(index !== idx){
        v.push(el);
      }
    });
    setCodes(v);
  }

  const topCode = (index) => {
    const t = codes[index];
    const v = [];
    codes.forEach( (el, idx) => {
      if(index !== idx){
        v.push(el);
      }
    });
    setCodes([t,...v]);
  }

  const originalCode = (index) => {
    const t = codes;
    t[index].isOriginalCode = !t[index].isOriginalCode;
    setCodes([...t]);
  }

  const visibleCode = (index) => {
    const t = codes;
    t[index].visible = !t[index].visible;
    setCodes([...t]);
  }

  const addCodes = () => {
    if(!codesMaker || !codesValue) return;
    if(codesMaker.length < 2 || codesValue.length < 4) return;

    const m = String(codesMaker).toUpperCase();
    const v = String(codesValue)
    .replace(/,/g,', ')
    .replace(/;/g,'; ')
    .replace(/\|/g,' | ')
    .replace(/\s\s/g,' ');

    setCodes([...codes, {maker: m, value: v, visible: true, isOriginalCode: false }]);
    setCodesMaker('');
    setCodesValue('');
    try {
      document.getElementsByName('codes_maker')[0].focus();      
    } catch (error) {}
  }

  const clearAll = () => {
    setSubmitEnabled(true);
    setName('');
    setCode('');
    setFileName('');
    formFileClear();
  }

  const Old = (p) => (
    <div style={{fontSize: 10, paddingLeft: 10, color: '#999'}}>
      {p.children}
    </div>
  )

  // useEffect(()=>{
  // },[]);

  return (
    <Container component="main" maxWidth="sm">
      <div>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>

            {/* <Grid container spacing={2}> */}
              <Grid item xs={12}>
                <h2 className={classes.title}>Cadastro</h2>
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <InputText name="code" label="Código Peça" value={code} /> */}
                <TextField 
                  fullWidth
                  style={{backgroundColor:'#ffffff'}}
                  InputLabelProps={{ shrink: true }} shrink
                  // disabled={disab}
                  autoComplete="code"
                  name="code"
                  variant="outlined"
                  label="Código Prod."
                  size="small"
                  onChange={onChange}
                  value={code}
                  // {...prop}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputText name="id_system" label="Id Sistema" value={idSystem} onChange={onChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputText name="id" label="Id" value={id} onChange={onChange} disabled={true} />
              </Grid>
            {/* </Grid> */}

            <BoxPanel title="Descrição">
              <Grid container spacing={2}>
                <InputTextGrid name="name" label="Descrição Fiscal" value={name} onChange={onChange} fullWidth />
                <InputTextGrid name="nick_name" label="Descrição Simplificada (pdf)" value={nickName} onChange={onChange} />
              </Grid>
            </BoxPanel>

            <BoxPanel title="Informações Técnicas">
              <Grid container spacing={2}>
                <InputTextGrid name="motor" label="Motor" value={motor} onChange={onChange} />
                <InputTextGrid name="dimension" label="Dimensão" value={dimension} onChange={onChange} />
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }} shrink
                    fullWidth
                    name="application"
                    label="Aplicação"
                    multiline
                    rows={10}
                    value={application}
                    onChange={onChange}
                    variant="outlined"
                    style={{backgroundColor:'#ffffff'}}
                  />
                </Grid>
                <InputTextGrid name="tags" label="Tags" value={tags} onChange={onChange} />
              </Grid>
            </BoxPanel>
            
            <BoxPanel title="Grupos">
              <Grid container spacing={2}>
                <SelectGrid name="maker" label="Fabricante" value={maker} data={dataMakers} onChange={onChange} />
                <Old>{maker}</Old>
                <SelectGrid name="group" label="Tipo" value={groupType} data={dataGroups} onChange={onChange} />
                <Old>{groupType}</Old>
                <SelectGrid name="line" label="Linha" value={groupLine} data={dataLines} onChange={onChange} />
                <Old>{groupLine}</Old>
              </Grid>
            </BoxPanel>

            <BoxPanel title="Codes (Será descontinuado)">
              <Grid container spacing={2}>
                <InputTextGrid name="code_original" label="Código Original" value={codeOriginal} onChange={onChange} />
                {/* <Divider className={classes.divider} /> */}
                <InputTextGrid name="code_original_2" label="Código Original 2" value={codeOriginal2} onChange={onChange} />
                <InputTextGrid name="code_similar" label="Código Similar" value={codeSimilar} onChange={onChange} />
              </Grid>
            </BoxPanel>

            <BoxPanel title="Codes">
              <Grid container spacing={2}>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                      <InputText name="codes_maker" label="Fabricante" value={codesMaker} onChange={onChange} onKeyDown={onKeyDown} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputText name="codes_value" label="Código" value={codesValue} onChange={onChange} onKeyDown={onKeyDown} />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <a href="#add" style={styles.addButton} onClick={addCodes} alt="Add" title="Add">
                        <div style={styles.addIcon}>
                          add
                        </div>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ul style={styles.codesUl}>
                    {codes.map( (el, index) => (
                      <li style={{...styles.codesLi, opacity: el.visible ? 1 : 0.5, color: el.isOriginalCode ? 'green' : null}} key={index}>
                        <div style={{width: 120, float: 'left', fontWeight: 600 }}>{el.maker}</div>
                        <div style={{width: 180, float: 'left', }}>{el.value}</div>
                        <a href="#delete" style={styles.btnDelete} onClick={()=>deleteCode(index)}>Delete</a>
                        <a href="#top" style={styles.btnUp} onClick={()=>topCode(index)}>Topo</a>
                        <a href="#original" style={styles.btnOriginal} onClick={()=>originalCode(index)}>{ el.isOriginalCode === true ? 'Original' : 'Similiar' }</a>
                        <a href="#visible" style={styles.btnVisible} onClick={()=>visibleCode(index)}>{ el.visible === true ? 'Visível' : 'Oculto' }</a>
                      </li>
                    ))}
                  </ul>
                </Grid>

              </Grid>
            </BoxPanel>

            <BoxPanel title="Linha de Fabricação">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isDiesel} onChange={handleChangeDiesel} name="is_diesel" />}
                    label="Diesel"
                  />
                </Grid>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isAgri} onChange={handleChangeAgri} name="is_agri" />}
                    label="Agrícola"
                  />
                </Grid>
              </Grid>
            </BoxPanel>

            <BoxPanel title="Classificação">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isVisible} onChange={handleChangeVisible} name="is_visible" />}
                    label="Visível"
                  />
                </Grid>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isNew} onChange={handleChangeNew} name="is_new" />}
                    label="Lançamento"
                  />
                </Grid>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isOff} onChange={handleChangeOff} name="is_off" />}
                    label="Promoção"
                  />
                </Grid>
                <Grid item xs={12} md={6} style={styles.checkbox}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isHighlight} onChange={handleChangeHighlight} name="is_hightlight" />}
                    label="Destaque"
                  />
                </Grid>
              </Grid>
            </BoxPanel>

            <Divider className={classes.divider} />
            {/*props.data.code.toUpperCase()*/}
            <Grid item xs={4} sm={4}>
              <img src={`${imageHost}products/V-001.jpg`} title="Produto" alt="Produto"
                style={{maxWidth:100, maxHeight:100}} 
                />
            </Grid>
            <Grid item xs={4} sm={4}>
            2
            </Grid>
            <Grid item xs={4} sm={4}>
            3
            </Grid>

            <Divider className={classes.divider} />

            <If value={fileUpload===null}>
              <Grid item xs={12} sm={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="file"
                  // multiple
                  type="file"
                  onChange={onChangeFile}
                />
                <label htmlFor="file">
                  <Button variant="contained" component="span" disableElevation={true} fullWidth style={{padding:7}} disabled={!fileUploadSubmitEnabled}>
                    upload file 
                  </Button>
                </label>
              </Grid>
              <LinearProgress variant="determinate" value={fileUploadLoad} fullWidth />
            </If>
            <If value={fileUpload !== null}>
              <Grid container spacing={1} xs={12} sm={12} style={{fontSize:'12px'}}>
                <Grid item xs={7} sm={7} style={{paddingLeft:15}}>
                {fileUploadLoad === 0 || fileUploadLoad === 100 ? '' : (fileUploadLoad + '% - ')} 
                {fileUploadName}
                </Grid>
                <Grid item xs={3} sm={3}>
                {fileUploadSize} 
                </Grid>
                <Grid item xs={2} sm={2} style={{paddingRight:5,textAlign:'right',cursor:'pointer'}}>
                  <button onClick={()=>formFileClear()}>Delete</button>
                </Grid>
              </Grid>
            </If>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Create
              </Button>

            </Grid>
          </Grid>

        </form>
      </div>
    
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  );
}

const styles = {
  addButton: {
    margin: 0,
    padding: 0,
    width:' 100%',
    height: 37,
    border: '1px solid #bbbbbb',
    display: 'block',
    borderRadius: 4,
    cursor: 'pointer',
    textDecoration: 'none', 
    color: '#1c4977',
    backgroundColor: '#ffffff',
  },
  addIcon: {
    padding: 5,
    paddingTop: 10,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 600,
  },
  codesUl: {
    listStyleType: 'none',
    padding: '0px 10px',
  },
  codesLi: {
    listStyleType: 'none',
    display: 'block',
    width: '100%',
    borderBottom: '1px solid #dddddd',
    clear: 'both',
  },
  btnDelete: {
    float: 'right',
    color: 'red',
    padding: '1px 5px',
    borderRadius: 3,
    fontSize: 13,
  },
  btnOriginal: {
    float: 'right',
    color: '#1c4977',
    padding: '1px 5px',
    borderRadius: 3,
    fontSize: 13,
  },
  btnVisible: {
    float: 'right',
    color: '#1c4977',
    padding: '1px 5px',
    borderRadius: 3,
    fontSize: 13,
  },
  btnUp: {
    float: 'right',
    color: '#1c4977',
    padding: '1px 5px',
    borderRadius: 3,
    fontSize: 13,
  },
  btnDown: {
    float: 'right',
    color: '#1c4977',
    padding: '1px 5px',
    borderRadius: 3,
    fontSize: 13,
  },
  checkbox: {
    // paddingLeft: 25,
    paddingLeft: 15,
  },
}