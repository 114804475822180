import { Grid } from "@material-ui/core";
import InputText from "./InputText";

const InputTextGrid = (properties) => {
  return (
    <Grid item xs={properties.xs || 12} sm={properties.sm || 12} md={properties.md || 12} lg={properties.lg || 12}>
      <InputText {...properties} />
    </Grid>
  )
}
export default InputTextGrid;