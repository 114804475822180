import React from 'react';
import Exports from '../../../resources/fabricjs/Exports';
import Images from '../../../resources/fabricjs/Images';
import MiniCanvas from '../../../resources/fabricjs/MiniCanvas';
import { TextField } from '@mui/material';
import UtilsCanvas from '../../../resources/utils/UtilsCanvas';
import UtilsObject from '../../../resources/utils/UtilsObject';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Grid } from '@material-ui/core';

const Input = styled('input')({
  display: 'none',
});

export default function ImageEdition() {

  const Exp = new Exports();

  const [sizeDefault, setSizeDefault] = React.useState(350);

  const [image350, setImage350] = React.useState(null);
  const [image250, setImage250] = React.useState(null);
  const [image150, setImage150] = React.useState(null);
  const [image50, setImage50] = React.useState(null);

  const [filename, setFilename] = React.useState('');
  const [canvas, setCanvas] = React.useState(null);

  const size = {
    width: 350, 
    height: 350, 
    watherMark: false, 
    name: 'canvas' 
  };

  const addImageData = (event) => {

    // document.getElementById('file').files[0].name;
    // document.getElementById('file').files[0].size;
    // document.getElementById('file').files[0].type;

    setCanvas(canvas.remove(canvas.item(0)));

    const dom  = document.getElementById('imageSelected');
    const size = dom.files[0].size;

    if(size < 5031336 && canvas){
      new Images().upload(canvas, event, {width: 350, top: -1, left: -1});
      const fn = String(event.target.value).split(/(\\|\/)/g).pop().toUpperCase().split('.')[0];
      setFilename(fn.substr(0,2) === 'V-' ? fn : 'V-');
      setSizeDefault(350);
      genImages();
    } else {
      window.alert("Imagem não pode ser carregada, muito grande. Máximo 5MB.");
    }

  };

  const addImageJPG = () => {

    setCanvas(canvas.remove(canvas.item(0)));

    canvas && new Images().add(canvas, {
      source: "assets/images/sample/filter.jpg",
      top: 10,
      left: 10,
      relativePosition: true,
    });

  };

  const genImages = (event) => {
    setTimeout(() => {

      canvas && Promise.all([
        Exp.toBase64(canvas, {format: 'png' }).then( data => setImage350(data.data) ),
        Exp.toBase64(canvas, {format: 'jpeg', quality: 0.6, multiplier: (250/350)}).then( data => setImage250(data.data) ),
        Exp.toBase64(canvas, {format: 'jpeg', quality: 0.5, multiplier: (150/350)}).then( data => setImage150(data.data) ),
        Exp.toBase64(canvas, {format: 'jpeg', quality: 0.4, multiplier: (50/350)}).then( data => setImage50(data.data) ),  
      ]).then(() => {
        console.log('Opa!');
      });
  
    }, 300);
  };

  const zoom = (number) => {

    let sd;

    if(number === 'reset' || number === null) {
      sd = size.width;
    } else {
      sd = sizeDefault + number;
    }

    canvas._objects.length && canvas.setActiveObject(canvas.item(0));

    UtilsObject.getActive(canvas).then( object => {
      object.scaleToWidth(sd);
      canvas.centerObject(object);
      setCanvas(canvas);
      UtilsCanvas.renderAll(canvas);
      genImages();
    });

    setSizeDefault(sd);
  }

  const rotate = (number) => {

    canvas._objects.length && canvas.setActiveObject(canvas.item(0));

    UtilsObject.getActive(canvas).then( object => {
      if(number === 'reset' || number === null) {
        UtilsObject.rotate(canvas, object, 0);
      } else {
        UtilsObject.rotate(canvas, object, object.angle + number);
      }
      setCanvas(canvas);
      UtilsCanvas.renderAll(canvas);
      genImages();
    });
  };

  return (
    <div style={{ padding:1 }}>

      <div style={{ padding:50 }}>



        <div style={styles.containerImages}>
            
          <div style={{...styles.itemImageEdition, width: size.width, height: size.height}} onMouseUp={genImages}>
            <MiniCanvas canvas={setCanvas} width={size.width} height={size.height} name={size.name}/>
          </div>
          <div style={{...styles.itemImages, width: size.width, height: size.height}} onMouseOver={genImages}>
            <div style={{overflowY: 'scroll', height: 350, border: '1px solid #aaaaaa', backgroundColor: '#cccccc' }}>
              <div style={{backgroundColor:'#eeeeee', padding: '10px 15px', fontSize: 12, }}>
                Imagens redimencionadas {filename && `(${filename})`}
              </div>
              {image50 && <img style={styles.img} src={image50} alt="ExportedImage"/> }
              {image150 && <img style={styles.img} src={image150} alt="ExportedImage"/> }
              {image250 && <img style={styles.img} src={image250} alt="ExportedImage"/> }
              {image350 && <img style={styles.img} src={image350} alt="ExportedImage"/> }
            </div>
          </div>

        </div>

            {/* <p style={styles.blocks}>
              <label htmlFor="imageSelected">
                <Input accept="image/*" id="imageSelected" multiple type="file" onChange={addImageData} />
                <Button variant="contained" component="span" fullWidth>
                  Escolher Imagem
                </Button>
              </label>
            </p> */}

            <div>

            <p style={styles.blocks}>
              Zoom +
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" fullWidth>
                <Button onClick={() => zoom(-30)}>-30</Button>
                <Button onClick={() => zoom(-10)}>-10</Button>
                <Button onClick={() => zoom(-5)}>-5</Button>
                <Button onClick={() => zoom('reset')}>0</Button>
                <Button onClick={() => zoom(+5)}>+5</Button>
                <Button onClick={() => zoom(10)}>+10</Button>
                <Button onClick={() => zoom(30)}>+30</Button>
              </ButtonGroup>
            </p>

            <p style={styles.blocks}>
              Rotate &ang;
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" fullWidth>
                <Button onClick={() => rotate(-30)}>-30&deg;</Button>
                <Button onClick={() => rotate(-10)}>-10&deg;</Button>
                <Button onClick={() => rotate(-5)}>-5&deg;</Button>
                <Button onClick={() => rotate('reset')}>0&deg;</Button>
                <Button onClick={() => rotate(+5)}>+5&deg;</Button>
                <Button onClick={() => rotate(+10)}>+10&deg;</Button>
                <Button onClick={() => rotate(+30)}>+30&deg;</Button>
              </ButtonGroup>
            </p>

            {/* <p style={styles.blocks}>
              Código do Produto
              {filename && <TextField id="filename" type="text" size="small" fullWidth value={filename} text={filename} onChange={(e)=>{setFilename(e.target.value)}} />}
            </p> */}

            <p style={styles.blocks}>
              Salvar imagens
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" fullWidth>
                <label htmlFor="imageSelected" style={{width: '50%'}}>
                  <Input accept="image/*" id="imageSelected" multiple type="file" onChange={addImageData} />
                  <Button size="medium" variant="contained" component="span" fullWidth style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    Abrir
                  </Button>
                </label>
                {/* <Button style={{width: '50%'}} onClick={genImages}>Gerar</Button> */}
                <Button size="medium" style={{width: '50%'}} onClick={genImages} disabled>Salvar</Button>
              </ButtonGroup>
            </p>

          </div>

      </div>

    </div>
  );
} 

const styles = {
  img: {
    margin: '10px 0 10px 10px',
    border: '1px dotted #444444',
  },
  containerImages:{
    display: 'flex',
  },
  itemImageEdition: {
    width: 350, 
    height: 350, 
    border: '1px solid #cccccc',
  },
  itemImages: {
    flex: 1,
  },
  blocks: {
    width: 352,
    margin: '3px 0',
    fontSize: 12,
  },
};