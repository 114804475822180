import React from "react";
import Home from "../pages/home";
import Products from "../pages/products";
import Pdf from "../pages/pdf/Pdf";
import {Redirect} from 'react-router-dom';
import ImageEdition from './../pages/images/ImageEdition';
import ProductCreate from "../pages/admin/formulary/ProductCreate";
import Admin from "../pages/admin";

// Produtos
// Ordens de Orçamento
// Representantes
// Troca / Reposiçaão
// Contato
// ADMINISTRAÇÃO
// Área Restrita
// Meus Dados
// Integração API
// PDF
// Sobre

const routers = [
  {
    label: "Início",
    path: "/",
    // component: <Home/>,
    component: <Redirect to="/products"/>,
    hiddenMenu: false,
    exact: true,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Produtos",
    path: "/products",
    component: <Products/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Ordem de Orçamento",
    path: "/order",
    component: <Home/>,
    hiddenMenu: true,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Representantes",
    path: "/representants",
    component: <Home/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Troca / Reposição",
    path: "/replace-product",
    component: <Home/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Contato",
    path: "/contato",
    component: <Home/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  // {
  //   label: 'ADMINISTRAÇÃO',
  //   divider: true,
  // },
  {
    label: "Área Restrita",
    path: "/admin",
    component: <Admin/>,
    hiddenMenu: true,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Edição de Imagens",
    path: "/images-edition",
    component: <ImageEdition/>,
    hiddenMenu: true,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Edição de Peças",
    path: "/product-edition",
    component: <ProductCreate/>,
    hiddenMenu: true,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Meus Dados",
    path: "/my-profile",
    component: <Home/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Sobre",
    path: "/about",
    component: <Home/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "PDF",
    path: "/pdf",
    component: <Pdf/>,
    hiddenMenu: false,
    exact: false,
    icon: null,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
];
export default routers;
