import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CartListForm from './CartListForm';
import { TextField } from '@mui/material';

export default function CartModal(props) {

  const descriptionElementRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);

    const handleClose = () => {
    setOpen(false);
    props.setOpen && props.setOpen(false);
  };

  const setDataChange = (data) => {
    props.setData && props.setData(data);
  };

  const deleteCartItem = (index) => {
    props.deleteCartItem && props.deleteCartItem(index);
  }

  React.useEffect(() => {
    data.length === 0 && handleClose();
  }, [data]);

  React.useEffect(() => {
    props.data !== undefined && setData(props.data);
  }, [props.data]);

  React.useEffect(() => {
    props.open !== undefined && setOpen(props.open);
    console.log(props.open)

    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={`paper`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={`sm`}
      >
        <DialogTitle id="scroll-dialog-title">Carrinho</DialogTitle>
        <DialogContent dividers={true}
          ref={descriptionElementRef}>
          {/* <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > */}
            <CartListForm data={data} deleteCartItem={deleteCartItem} setData={setDataChange}/>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <div style={{width: '100%', padding: 10, paddingBottom: 0 }}>
            <TextField
              InputLabelProps={{ shrink: true }} shrink
              fullWidth
              name="description"
              label="Observações"
              multiline
              rows={4}
              // value={application}
              // onChange={onChange}
              variant="outlined"
              style={{backgroundColor:'#eeeeee'}}
            />
          </div>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">Cancel</Button>
          <Button onClick={handleClose} variant="outlined" color="inherit">Enviar Orçamento</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}