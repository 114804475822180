import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const styles = {
  menuItem: {
    clear: 'both',
    display: 'block',
    width: '100%',
    textAlign: 'left',
    fontSize: '90%',
  },
}
const SelectInput = ({value, name, label, disabled, onChange, data}) => (
  <FormControl fullWidth size="small">
    <InputLabel id={`id-label-${name}`} InputLabelProps={{ shrink: true }} shrink style={{backgroundColor:'#ffffff'}} size="small">{label}</InputLabel>
    <Select

      fullWidth

      labelId={`id-label-${name}`}
      style={{backgroundColor:'#ffffff'}}
      InputLabelProps={{ shrink: true }} shrink
      disabled={ disabled === undefined ? false : disabled }
      autoComplete={name}
      name={name}
      variant="outlined"
      label={label}
      size="small"
      onChange={onChange}
      value={value}
      
    >
      {data.map((el, index) => (
        <MenuItem size="small" style={styles.menuItem} value={el.label || el.name || el} key={index} fullWidth>{el.label || el.name || el}</MenuItem>
      ))}
    </Select>
  </FormControl>
);
export default SelectInput;