import { TextField } from '@mui/material';
import React, {useEffect, useState} from 'react';
import ApiServer from '../../../services/ApiServer';
// import CheckboxList from './CheckboxList';
// import BasicTable from './BasicTable';
import BasicList from './BasicList';
import Skeleton from '@mui/material/Skeleton';
// import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProductDescription from './ProductDescription';
import MenuHightlight from './MenuHightlight';
import CartList from './cart/CartList';
import { Button, makeStyles } from '@material-ui/core';
import CartModal from './cart/CartModal';

const MIN_WORD_TO_SEARCH = 3;

const useStyles = makeStyles(theme => ({
  dashbordColumns: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));

const dataMakers = [
  'AGRALE',
  'CASE',
  'CATERPILLAR',
  'CBT',
  'CUMMINS',
  'DAF',
  'DEUTS',
  'DOOSAM',
  'DYNAPAC',
  'FIAT ALLIS',
  'FORD',
  'GERADORES',
  'GM',
  'HUBER',
  'HYUNDAI',
  'IDEAL',
  'INTERNACIONAL',
  'IVECO',
  'JACTO',
  'JCB',
  'JOHN DEERE',
  'KOMATSU',
  'MARITIMO',
  'MASSEY',
  'MERCEDES',
  'MULLER',
  'NEW HOLLAND',
  'RANDON',
  'SCANIA',
  'SINOTRUK',
  'TIANGONG',
  'UTILITÁRIO',
  'VALTRA',
  'VOLKSWAGEN',
  'VOLVO',
  'WORD',
  'XCMG',
  'YIENENG',
];

const dataGroups = [
  "ALUMÍNIO", 
  "AGRICOLA", 
  "CONJUTO VISCOSO",
  "EMBREAGEM VISCOSA", 
  "HÉLICES", 
];

const dataLines = [
  "ALUMÍNIO",
  "CONJUNTOS / VENTILADORES",
  "EMBREAGEM VISCOSA",
  "FERRO",
  "HÉLICES / VENTILADORES",
];

function Products () {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  const [dataSelected, setDataSelected] = useState(null);
  const [dataIndex, setDataIndex] = useState(-1);

  const [filterResume, setFilterResume] = useState('');
  const [filterMaker, setFilterMaker] = useState('');
  const [filterGroup, setFilterGroup] = useState('');
  const [filterLine, setFilterLine] = useState('');
  const [filterHighlight, setFilterHighlight] = useState(null);

  const [dataCart, setDataCart] = useState([]);
  const [widthCart, setWidthCart] = useState(20);
  const [openCart, setOpenCart] = useState(false);
  
  function init() {

    window.data || (window.data = []);
    window.dataFilter || (window.dataFilter = []);

    if(window.data.length){
      setData(window.data);
    } else {
      getProducts();
    }

    if(window.dataFilter.length){
      setDataFilter(window.dataFilter);
    }

  }

  const changeMaker = (event) => {

    // clear filter
    setFilterHighlight(null);

    setFilterMaker(event.target.value);
    setDataFilter(filterAll(data, {
      word: search,
      maker: event.target.value,
      group: filterGroup,
      line: filterLine,
      highlight: filterHighlight,
    }));
    setFilterResume( `buscar: ${ search.length > 20 ? String(search).substr(0, 20) : search }`);
  };

  const changeGroup = (event) => {

    // clear filter
    setFilterHighlight(null);

    setFilterGroup(event.target.value);
    setDataFilter(filterAll(data, {
      word: search,
      maker: filterMaker,
      group: event.target.value,
      line: filterLine,
      highlight: filterHighlight,
    }));
    setFilterResume(`buscar: ${ search.length > 20 ? String(search).substr(0, 20) : search }`);

  };

  const changeLine = (event) => {

    // clear filter
    setFilterHighlight(null);

    setFilterLine(event.target.value);
    setDataFilter(filterAll(data, {
      word: search,
      maker: filterMaker,
      group: filterGroup,
      line: event.target.value,
      highlight: filterHighlight,
    }));
    setFilterResume(`buscar: ${ search.length > 20 ? String(search).substr(0, 20) : search }`);
  };

  function getProducts() {

    setLoading(true);

    // const url = 'https://valpec.gnnc.com.br/api/products/get';
    const data = {};
    new ApiServer().get('products', 'get', data, {}).then( data => {
      if(data.statusText==='OK'){
        setData(data.data);
        window.data = data.data;
      }
      setLoading(false);
    }).catch( err => {
      console.log(err);
      setLoading(false);
    });
  }

  function removeAcento(text){
    if(!text) return '';
    return text
    .toLowerCase()
    .replace(/[ÁÀÂÃ]/gi,'a')
    .replace(/[ÉÈÊ]/gi, 'e')
    .replace(/[ÍÌÎ]/gi, 'i')
    .replace(/[ÓÒÔÕ]/gi,'o')
    .replace(/[ÚÙÛ]/gi, 'u')
    .replace(/[Ç]/gi,   'c')
    // remove special
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function filterTimeout(event) {

    if(loading) return;

    // set focus on list
    if (event.key === 'ArrowDown') {
      document.getElementById('li0').click();
      document.getElementById('checkbox0').focus();
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    
    if (['ArrowUp', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    // filter
    const word = event.target.value || '';

    if( word.length >= MIN_WORD_TO_SEARCH) {
      setFilterResume('Pesquisando...');
      setLoading(true);
      filterWord(word);
    } else {
      setLoading(false);
      filterWord('');
    }

  }

  function filterAll(el, options) {

    if(loading) return [];

    let {word, maker, group, line, highlight} = options;

    word  = removeAcento(word);
    maker = removeAcento(maker); 
    group = removeAcento(group); 
    line  = removeAcento(line); 

    let result;
    if(highlight) {
      result = el.filter((el) => el[highlight] === 1);
    }
    else if(maker && group && line) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(maker) > -1 
        && removeAcento(el.search).indexOf(group) > -1 
        && removeAcento(el.search).indexOf(line) > -1 
      );
    } 
    else if(maker && group) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(maker) > -1 
        && removeAcento(el.search).indexOf(group) > -1 
      );
    } 
    else if(maker && line) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(maker) > -1 
        && removeAcento(el.search).indexOf(line) > -1 
      );
    } 
    else if(group && line) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(group) > -1 
        && removeAcento(el.search).indexOf(line) > -1 
      );
    } 
    else if(maker) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(maker) > -1 
      );
    } 
    else if(group) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(group) > -1 
      );
    } 
    else if(line) {
      result = el.filter(
        (el) => removeAcento(el.search).indexOf(word) > -1 
        && removeAcento(el.search).indexOf(line) > -1 
      );
    } 
    else if(String(word).length >= MIN_WORD_TO_SEARCH){
      result = el.filter((el) => removeAcento(el.search).indexOf(word) > -1);
    } 
    else {
      result = [];
    }
    window.dataFilter = result;
    return result;

  }

  function clearFilters() {
    setFilterMaker('');
    setFilterGroup('');
    setFilterLine('');
    setFilterHighlight('');
    setDataFilter(filterAll(data, {
      word: search,
      maker: '',
      line: '',
      highlight: '',
    }));

    try {
      document.getElementById('outlined-search').focus();      
    } catch (error) {}
  }

  function filterWord(word) {

    // clear filter
    setFilterHighlight(null);

    word = String(word)
    .replace(/,/g, '')
    .replace(/;/g, '')
    .replace(/`/g, '')
    .replace(/'/g, '')
    .replace(/"/g, '')
    .replace(/-/g, '')
    .replace(/\./g, '')
    .replace(/\\/g, '')
    .replace(/\//g, '')
    .replace(/_/g, '')
    .replace(/\+/g, '')
    .replace(/\^/g, '')
    .replace(/\s\s/g, '')
    .replace(/\r/g, '')
    .replace(/\n/g, '')
    .replace(/\t/g, '')
    .trim();

    word = removeAcento(word);

    if(!word) {

      setSearch('');
      setDataFilter(filterAll(data, {
        word: '',
        maker: filterMaker,
        group: filterGroup,
        line: filterLine,
        highlight: filterHighlight,
      }));
      setFilterResume('');
    
    } else {
      
      try {

        let result = filterAll(data, {
          word: word,
          maker: filterMaker,
          group: filterGroup,
          line: filterLine,
          highlight: filterHighlight,
        });

        setSearch(word);
        setDataFilter(result);
        setFilterResume( `
          buscar: ${ word.length > 20 ? String(word).substr(0, 20) : word } 
        `);
  
      } catch (error) {

        setSearch('');
        setDataFilter([]);
        setFilterResume( 'Algum problema encontrado' );
  
      }

      /*return el.price <= 1000 &&
      el.sqft >= 500 &&
      el.num_of_beds >=2 &&
      el.num_of_baths >= 2.5;*/

      // document.querySelector("#table-scroll").scrollTop = 0;
      // setDataDetails([]);

    }

    setTimeout(() => {
      setLoading(false);
    }, 500);

  }

  function setHighlight(el) {

    if(!el) return;

    // clear filter
    setSearch('');
    setFilterMaker('');
    setFilterLine('');
    
    setFilterHighlight(el);
    setDataFilter(filterAll(data, {
      word: search,
      maker: filterMaker,
      group: filterGroup,
      line: filterLine,
      highlight: el.type, // is_off | is_new
    }));
    setFilterResume(`${el.label}`);
  
  }

  const onKeyPressed = (e) => {

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();
    }

    let n, o;
    setDataIndex((prev) => { 
      n = prev;
      if (e.key === "ArrowUp") {
        if(n >= 0){
          n -= 1;
          o = window.dataFilter[n];
        }
        return n;
      } else if (e.key === "ArrowDown") {
        if(n < window.dataFilter.length-1){
          n += 1;
          o = window.dataFilter[n];
        }
        return n;
      } else {
        n = prev;
        o = window.dataFilter[n];
      return n;
      }
    });
    setDataSelected(o);
    changeColor(n);
    // setDataSelected(dataFilter[n]);
    // console.log('C', n, dataFilter[n], dataSelected);

  };

  function changeColor(index) {

    const allSelectedRowsStyle = [...document.getElementsByClassName("active-line")];    
    allSelectedRowsStyle.map((e) => e.classList.remove("active-line"));

    const active = document.getElementById(`li${index}`);
    // active && active.classList.add('active-line');

    if(active) {
      active.classList.add("active-line");
      active.scrollIntoView(true);  
    }

  }

  const addItemCart = (element) => {
    const f = dataCart.find(el => el.id === element.id);
    if(f) return;
    // add item list
    setDataCart((prev) => [...prev, {...element, quantity: 1 }]);
    // open panel
    setWidthCart(220);
  }

  const deleteCartItem = (index) => {
    const v = [];
    // remove item list
    dataCart.forEach((el, idx) => index !== idx && v.push(el));
    setDataCart(v);
    // open panel
    v.length === 0 && setWidthCart(20);
  }

  useEffect(() => {
    init();
    document.addEventListener('keydown', onKeyPressed);
    return () => {
      document.removeEventListener('keydown', onKeyPressed);
    }
  },[]);

  return (
    <div  className={classes.dashbordColumns}>
      <div style={styles.dashbordProducts}>
        <div style={styles.top}>
          <div style={styles.topPadding}>
            <div style={{display:'flex'}}>
              <div style={{flex:1, padding: 7}}>
                <TextField id="outlined-search" label="Pesquisar" type="search" size="small" fullWidth text={search} onKeyUp={filterTimeout} />
              </div>
              <div style={{flex:1, padding: '18px 0 0 10px'}}>
                <div style={styles.searchResume}>
                  {dataFilter.length || ''}
                </div>
              </div>
              <div style={{width: 120, paddingRight: 0, alignSelf: 'center'}}>
                <MenuHightlight dataSelected={setHighlight} disabled={loading}/>
              </div>
              <div style={{width: 150, paddingRight: 10}}>

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="maker-label" InputLabelProps={{ shrink: true }} shrink style={{backgroundColor:'#ffffff'}} size="small">Fabricante</InputLabel>
                  <Select 
                    InputLabelProps={{ shrink: true }} shrink
                    labelId="maker-label"
                    value={filterMaker}
                    label="Fabricante"
                    size="small"
                    onChange={changeMaker}
                    style={{fontSize: 13, padding: 2}}
                    disabled={loading}
                  >
                    <MenuItem style={styles.menuItem} value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {dataMakers.map((el, index) => (
                      <MenuItem style={styles.menuItem} value={el}>{el}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </div>
              <div style={{width: 150, paddingRight: 10}}>

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="group-label" InputLabelProps={{ shrink: true }} shrink style={{backgroundColor:'#ffffff'}} size="small">Grupo</InputLabel>
                  <Select 
                    InputLabelProps={{ shrink: true }} shrink
                    labelId="group-label"
                    value={filterGroup}
                    label="Fabricante"
                    size="small"
                    onChange={changeGroup}
                    style={{fontSize: 13, padding: 2}}
                    disabled={loading}
                  >
                    <MenuItem style={styles.menuItem} value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {dataGroups.map((el, index) => (
                      <MenuItem style={styles.menuItem} value={el}>{el}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </div>
              <div style={{width: 150, paddingRight: 10}}>

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="line-label" InputLabelProps={{ shrink: true }} shrink style={{backgroundColor:'#ffffff'}} size="small">Linha</InputLabel>
                  <Select 
                    InputLabelProps={{ shrink: true }} shrink
                    labelId="line-label"
                    value={filterLine}
                    label="Linha"
                    size="small"
                    onChange={changeLine}
                    style={{fontSize: 13, padding: 2}}
                    disabled={loading}
                  >
                    <MenuItem style={styles.menuItem} value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {dataLines.map((el, index) => (
                      <MenuItem style={styles.menuItem} value={el}>{el}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </div>
            </div>
          </div>
        </div>
        <div style={{...styles.searchDescription, display: (filterMaker || filterGroup || filterLine) ? 'block' : 'none'}}>
          <a href="#clear" style={{textDecoration: 'none', color: 'blue', paddingRight: 10}} onClick={clearFilters}>limpar filtro</a>
          {filterMaker && ` • ${filterMaker} `}
          {filterGroup && ` • ${filterGroup} `}
          {filterLine && ` • ${filterLine} `}
          <div style={{float:'right'}}>
            {/* {dataIndex}  */}
            {filterResume}
          </div>
        </div>
        <div style={styles.content} id="content-list">
          <div style={styles.contentPadding}>
            { loading ? (
              <div>
                <Skeleton width="80%" height={40} style={{ marginBottom: 1 }} />
                <Skeleton height={40} style={{ marginBottom: 1 }} animation="wave" />
                <Skeleton width="80%" height={40} style={{ marginBottom: 1 }} />
                <Skeleton height={40} style={{ marginBottom: 1 }} animation="wave" />
              </div>
            ) : 
              !dataFilter.length ? (
                <div>
                  Pesquisa alguma informação relevante. Mínimo 3 dígitos.
                </div>
              ) : <BasicList data={dataFilter} dataSelected={setDataSelected} dataIndex={setDataIndex} addItemCart={addItemCart}/>
            }
            {/* <BasicTable data={dataFilter}/> */}
            {/* {dataFilter.map( (element, index) => {
              return (
                <li key={index}>
                  {element.code} - 
                  {element.name} - 
                  {element.maker}
                </li>
              )
            })} */}
            {/* <CheckboxList data={dataFilter}/> */}
          </div>
        </div>
        <div style={styles.bottom}>
          <div style={styles.bottomPadding}>
            <ProductDescription data={dataSelected}/>
          </div>          
        </div>
      </div>
      <div style={{...styles.columnList, width: widthCart}}>
        <div style={styles.columnListPadding}>
            <div style={{display:'flex', flexDirection:'column', height:'100%', minWidth:220}}>
              <div style={{flex:1, overflowY:'scroll', width:'100%'}}>
                <CartList data={dataCart} deleteCartItem={deleteCartItem}/>
              </div>
              <div style={{height: 40, padding: 10}}>
                <CartModal data={dataCart} open={openCart} setOpen={setOpenCart} deleteCartItem={deleteCartItem}
                  setData={setDataCart}
                />
                <Button variant="outlined" color="inherit" fullWidth style={{maxWidth:200}} onClick={()=>setOpenCart(true)} > Criar Orçamento</Button>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
};

// https://stackoverflow.com/questions/28922126/how-to-align-flexbox-columns-left-and-right#28922140
const styles = {
  searchDescription: {
    borderBottom: '1px solid #aaaaaa',
    padding: '5px 10px',
    backgroundColor: '#74a2e1',
    fontSize: 12,
    color: '#111',
  },
  searchResume: {
    fontSize: 12,
    color: '#555',
    marginBottom: 5,
  },
  buttonLogo: {
    width: '100%',
    display: 'block',
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    marginTop: 15,
  },
  logo: {
    marginTop: '7px',
    marginLeft: '5px',
  },
  dashbordColumns: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  columnList: {
    display: 'flex',
    width: '200px',
    height: '100%',
    backgroundColor: '#eeeeee',
  },
  columnListPadding: {
    padding: 0,
  },
  dashbordProducts: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    // height: '100vh',
    height: '100%',
  },
  top: {
    // width: '100vh',
    width: '100%',
    height: 'auto',
    //flex: 1,
    // backgroundColor: '#dddddd',
    borderBottom: '1px solid #aaaaaa',
  },
  topPadding: {
    padding: '12px 10px 10px 10px',
  },
  content: {
    flex: 1,
    // padding: '30px 40px 50px 40px',
    overflowY: 'scroll',
    margin: 0,
  },
  contentPadding: {
    padding: 10,
  },
  bottom: {
    borderTop: '1px solid #aaaaaa',
    minHeight: 200,
    maxHeight: 200,
    margin: 0,
    paddingTop: 10,
  },
  bottomPadding: {
    padding: '0 10px',
  },
  menuItem: {
    clear: 'both',
    display: 'block',
    width: '100%',
    textAlign: 'left',
    fontSize: '90%',
    padding: '5px 15px',
  },
}

export default Products;

// #container {
//   width: 500px;
//   border: solid 1px #000;
//   display: flex;
// }

// #a {
//   width: 20%;
//   border: solid 1px #000;
// }

// #b {
//   width: 20%;
//   border: solid 1px #000;
//   height: 200px;
// }