import React from 'react';
import Products from '../products';
import AppAdminBar from './appBar/AppAdminBar';
import ProductCreate from './formulary/ProductCreate';

export default function Admin(props){
  return (
    <div style={styles.mainVertivcally}>
        <div style={styles.main}>
        <div style={styles.columnOne}>
        a
        </div>
        <div style={styles.columnOne}>
        a
        </div>
        <div style={styles.columnTwo}>
          {/* <ProductCreate/> */}
          <Products/>
        </div>  
      </div>
  </div>
  );
};

const styles = {
  mainVertivcally: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: 0,
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    height: '100vh',
    padding: 0,
  },
  columnOne: {
    padding: 0,
    width: '200px',
    backgroundColor: '#eeeeee',
    height: '100vh',
    borderRight: '1px solid #999999',
  },
  columnTwo: {
    flex: 1,
    overflowY: 'scroll',
  },
}