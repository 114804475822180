import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Avatar } from '@material-ui/core';

export default function CartList(props) { 

  const [data, setData] = React.useState([]);

  const deleteCartItem = (index) => {
    props.deleteCartItem && props.deleteCartItem(index);
  }

  React.useEffect(() => {
    props.data && setData(props.data);
  },[props]);

  return (
    <List sx={{ width: '100%', minWidth: 150}} dense={true}>
      {data.map((el, index) => {
        return (
          <ListItem
            style={{padding:0, paddingLeft: 10}}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => deleteCartItem(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            }>
            <ListItemAvatar>
              <Avatar>
                <img src={`https://valpec.gnnc.com.br/storage/products/${String(el.code).toUpperCase()}.jpg`} alt={el.code} style={{maxHeight:50}}
                  title={`${el.code} - ${el.name} - ${el.application}`}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={el.code} secondary={<div style={{fontSize:11}}>{el.maker}</div>} />
          </ListItem>
        );
      })}
    </List>
  );
}