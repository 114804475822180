import React from "react";

class UtilsFilter extends React.Component {

  // https://stackoverflow.com/questions/23561026/regex-to-remove-spaces-between-numbers-only
	static clearAll = (word) => {
    const reg = /(\d)\s+(?=\d)/g;
    word = word || "";
    return (
      word
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("\\","")
        .replaceAll("'", "")
        .replaceAll('"', "")
        .replaceAll("/", "")
        .replaceAll("_", "")
        .replaceAll("+", "")
        .replaceAll(",", "")
        .replaceAll(";", "")
        .replaceAll("^", "")
        .replaceAll("~", "")
				//.replaceAll("(?<=\\d) +(?=\\d)", "") // remove space between numbers only
				.replaceAll("(\\d)\\s(\\d)", "$1$2") // remove space between numbers only
				.replace(reg, "$1")
    );
  };

	static clearAllMatch = ( word, symbols ) => {
		if( !Array.isArray(symbols) ) return word;
		word = symbols.reduce( ( v, role ) => {
			return v.replaceAll(role,'');
		}, word);
    const reg = /(\d)\s+(?=\d)/g;
		const done = word
		//.replaceAll("(?<=\\d) +(?=\\d)", "") // remove space between numbers only
		.replaceAll("(\\d)\\s(\\d)", "$1$2") // remove space between numbers only
		.replace(reg, "$1");
		return done;
		
	}

}
export default UtilsFilter;
