import React from 'react';

const styles = {
  title: {
    display:'block', 
    color: '#777777', 
    margin: 0, 
    fontSize: 12,
    paddingBottom: 15, 
    paddingTop: 0,
    marginBottom: 5,
  }
}
const LittleTitle = (props) => {
  return (
    <h3 style={styles.title}>
      {props.children}
    </h3>
  )
}
export default LittleTitle;