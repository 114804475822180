import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

export default function BasicList(props) {

  const [data, setData] = useState([]);
  // const [activeIndex, setActiveIndex] = useState(-1);

  function isWhat (el) {
    if(el.is_new === 1) {
      return colorLines.green;
    }
    if(el.is_off === 1) {
      return colorLines.yellow;
    }
    return {};
  }

  function setActiveLine(element, index, lineObject) {

    try {
      props.dataSelected({...element, index});
    } catch (error) {}

    try {
      // setActiveIndex(index);
      props.dataIndex(index);
    } catch (error) {}

    const allSelectedRowsStyle = [
      ...document.getElementsByClassName("active-line"),
    ];
    
    // mapeia as linhas em destaque e remove o estilo de destaque
    allSelectedRowsStyle.map((e) =>
      e.classList.remove("active-line")
    );

    // marca a nova linha selecionada
    lineObject && lineObject.classList.add("active-line");

    // if (data.image1 || data.image2 || data.image3) {
    //   const bas = "";
    //   if (data.image1) bas = data.image1;
    //   else if (data.image2) bas = data.image2;
    //   else if (data.image3) bas = data.image3;
    //   const img = `data:image/jpg;base64,${bas}`;
    //   //const img = `${bas}`;
    //   setImagesSelected([img]);
    //   base64Img.img(img, "./", data.code + ".jpg");
    // } else {
    //   setImagesSelected([]);
    // }
   
    //document.querySelector("#list-scroll").scrollTop = 0;

    lineObject.onpaste = () => null;
    lineObject.oncopy = () => null;
    lineObject.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });

  }

  // const onKeyPressed = (e) => {
  //   setActiveIndex((prev) => {
  //     console.log('XX2');
  //     if (e.key === "ArrowUp") {
  //       return prev - 1;
  //     } else if (e.key === "ArrowDown") {
  //       return prev + 1;
  //     }
  //   });
  // };

  function onClickCheckbox(event){
    // event.preventDefault();
    event.stopPropagation();    
  }

  function onClickAddCart(event, element){
    // event.preventDefault();
    event.stopPropagation();
    props.addItemCart && props.addItemCart(element);
  }

  useEffect(() => {
    props.data && setData(props.data);
  },[props.data]);

  // quando os dados sao alterados da lista ele inicia a observacao dos objetos em blocks, cada bloco contém LIMIT_BLOCK
  useEffect(() => {
    document.getElementById("list-scroll").scrollIntoView(true);
    observerElements();
    return () => {
      observer !== null && observer.disconnect();
    }
  },[data]);

    let observer = null;
  const LIMIT_BLOKS = 50;
  const [overBlockIndexList, setOverBlockIndexList] = useState(1);

  // quando os elementos ficam visiveis, bloco 1, boock 2, bloco 3 ele para de observar
  // depois avisa que está no bloco x, alterando o seOverBlockIndexList(x);
  function intersectionCallback(entries) {    
    // console.log('intersectionCallback: Funcion', this);
    // isIntersecting is true when element and viewport are overlapping
    // isIntersecting is false when element and viewport don't overlap
    if(entries[0].isIntersecting === true){
      const blockIndex = entries[0].target.block;
      setOverBlockIndexList(prev => prev < blockIndex ? blockIndex : prev);
      console.log(entries[0].target.block, `#li${blockIndex*LIMIT_BLOKS}`);
      console.log('isIntersecting: Element has just become visible in screen');
      observer.unobserve(document.querySelector(`#li${blockIndex*LIMIT_BLOKS}`));
    }
  } 

  // inicia as observacoes
  const observerElements = () => {
    
    console.log('### isIntersecting: Function');
    observer !== null && observer.disconnect(); // disconnect as observações
    observer = new IntersectionObserver(intersectionCallback, { threshold: [0] });    

      let loops = 0; // loops of the LIMIT_BLOKS
      let rest  = 0; // sobra de peças
      let limit = 0;

    for(let i = 1; i < 20; ++i) {
      
      let add = LIMIT_BLOKS * i;
      if(add < data.length){
        limit = add; 
        loops = i;

        observer.observe(document.querySelector(`#li${i*LIMIT_BLOKS}`));
        document.getElementById(`li${i*LIMIT_BLOKS}`).block = i;
        // console.log(`li${i*LIMIT_BLOKS}`);

      } else {
        rest = data.length - limit;
        return;
      }
    }
    
  }

  return (
    <ul style={styles.ul} id="list-scroll">
      {data.map( (element, index) => {
        return (
          <li style={{...styles.li, ...isWhat(element)}} key={index} className="liItem" id={`li${index}`} block=""
            onClick={(e) => setActiveLine(element, index, e.currentTarget)}
            >
            {
              index < overBlockIndexList * LIMIT_BLOKS + LIMIT_BLOKS? 
              <React.Fragment>
                <div style={product.check}>
                  <input id={`checkbox${index}`} type="checkbox" style={product.checkbox} onClick={onClickCheckbox}/>
                </div>
                <div style={product.code}>{element.code}</div>
                <div style={{...product.name, ...product.labelRoot}}>
                  <div style={product.label}>
                    {/* {overBlockIndexList * 50} */}
                    {element.name}
                  </div>
                </div>
                <div style={product.maker}>{element.maker}</div>
                <div style={product.group_line}>{element.group_line}</div>
                <div style={product.add}>
                  {/* <IconButton style={{ padding:5, margin:0, marginTop: -6 }} onClick={(event)=>onClickAddCart(event, element)} >
                    <AddIcon />
                  </IconButton> */}
                  <button style={product.buttonAdd} onClick={(event)=>onClickAddCart(event, element)}
                  title="Adicionar no carrinho">Add</button>
                </div>
              </React.Fragment>
              : <div>Renderizando...</div>
            }
          </li>
        )
      })}
    </ul>
  );
}

const product = {

  labelRoot: {
    minWidth: 100,
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  buttonAdd: {
    margin: 0,
    marginTop: -2,
    padding: '5px 7px',
    backgroundColor: '#ffffff',
    border: '1px solid #999999',
    borderRadius: 3,
    fontSize: 11,
    cursor: 'pointer',
  },
  add: {
    width: 45,
    textAlign: 'right',
  },
  checkbox: {
    width: '18px',
    height: '18px',
  },
  check: {
    width: 35 ,
    float: 'left',
    fontWeight: '700',
    fontSize: 15,
    clear: 'both',
  },
  code: {
    color: '#49515f',
    width: 60,
    float: 'left',
    fontWeight: '700',
    fontSize: 15,
    clear: 'both',
  },
  name: {
    float: 'left',
    fontSize: 13,
    flex: 1,
  },
  group_line: {
    paddingTop: 1,
    width: 150,
    fontSize: 11,
    // lineHeight: 1,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    
  },
  maker: {
    paddingTop: 1,
    width: 120,
    fontSize: 11,
  },
}

const styles = {
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  li: {
    padding: 5,
    margin: 0,
    display: 'flex',
    clear: 'both',
    height: 22,
    borderBottom: '1px solid #dddddd',
  },
}

const colorLines = {
  green: {
    backgroundColor: '#45f248AA',
    borderBottom: '1px solid #45f248',
  },
  yellow: {
    backgroundColor: '#FED10AAA',
    borderBottom: '1px solid #FED10A',
  },
}