import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import { Avatar } from '@material-ui/core';
import { TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function CartListForm(props) { 

  const [data, setData] = React.useState([]);

  const deleteCartItem = (index) => {
    props.deleteCartItem && props.deleteCartItem(index);
  }

  const onChangeCount = (event, index) => {
    let q = event.target.value;

    if(!q.match(/^\d+$/)) return;
    if(!q) q = 1;
    if( q > 999) return;

    const v = [...data,];
    v[index].quantity = q >> 0;
    console.log(v);
    props.setData && props.setData(v);
  }

  React.useEffect(() => {
    props.data && setData(props.data);
  },[props]);

  return (
    <List sx={{ width: '100%', minWidth: 150}} dense={true}>
      {data.map((el, index) => {
        return (
          <ListItem
            key={index}
            style={{padding:0, paddingLeft: 10, borderBottom:'1px #dddddd solid',}}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => deleteCartItem(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            }>
            <ListItemAvatar>
              <Avatar>
                <img src={`https://valpec.gnnc.com.br/storage/products/${String(el.code).toUpperCase()}.jpg`} alt={el.code} style={{maxHeight:50}}
                  title={`${el.code} - ${el.name} - ${el.application}`}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${el.code} - ${el.maker}`} secondary={<div style={{fontSize:11}}>{el.name}</div>} />

            <ListItemAvatar style={{ maxWidth: 80, marginRight: 60}}>
              <TextField size="small" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} InputLabelProps={{ shrink: true }} shrink 
              value={el.quantity || 1 } onChange={(event) => onChangeCount(event, index)} />
            </ListItemAvatar>

          </ListItem>
        );
      })}
    </List>
  );
}